import React, { lazy, Suspense } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { GlobalProvider } from "./GlobalContext";
import Loading from "./components/elements/Loading";
import "./scss/main.scss";

const ABTest = lazy(() => import("./forms/abtest"));
const ABTests = lazy(() => import("./forms/abtests"));
const AccessControlProvider = lazy(() =>
  import("./forms/accessControlProvider")
);
const AccessControlProviders = lazy(() =>
  import("./forms/accessControlProviders")
);
const AccessPoint = lazy(() => import("./forms/accessPoint"));
const AccessPoints = lazy(() => import("./forms/accessPoints"));
const Account = lazy(() => import("./forms/account"));
const Accounts = lazy(() => import("./forms/accounts"));
const AdditionalProduct = lazy(() =>
  import("./forms/additionalProduct")
);
const AdditionalProducts = lazy(() =>
  import("./forms/additionalProducts")
);
const AdminSettings = lazy(() => import("./forms/adminSettings"));
const AnswerGroup = lazy(() => import("./forms/answerGroup"));
const AnswerGroupOption = lazy(() =>
  import("./forms/answerGroupOption")
);
const AnswerOption = lazy(() => import("./forms/answerOption"));
const AnswerOptionCategory = lazy(() =>
  import("./forms/answerOptionCategory")
);
const Blacklist = lazy(() => import("./forms/blacklist"));
const Block = lazy(() => import("./forms/block"));
const Blocks = lazy(() => import("./forms/blocks"));
const BlockSeat = lazy(() => import("./forms/blockSeat"));
const BookableResource = lazy(() =>
  import("./forms/bookableResource")
);
const BookableResources = lazy(() =>
  import("./forms/bookableResources")
);
const Booking = lazy(() => import("./forms/booking"));
const Bookings = lazy(() => import("./forms/bookings"));
const BookingPayment = lazy(() => import("./forms/bookingPayment"));
const BookingTicket = lazy(() => import("./forms/bookingTicket"));
const CalendarItem = lazy(() => import("./forms/calendarItem"));
const Campaign = lazy(() => import("./forms/campaign"));
const Campaigns = lazy(() => import("./forms/campaigns"));
const Channel = lazy(() => import("./forms/channel"));
const Channels = lazy(() => import("./forms/channels"));
const ChannelPriceList = lazy(() =>
  import("./forms/channelPriceList")
);
const ChannelPriceLists = lazy(() =>
  import("./forms/channelPriceLists")
);
const ChannelQuestion = lazy(() => import("./forms/channelQuestion"));
const Charity = lazy(() => import("./forms/charity"));
const Cities = lazy(() => import("./forms/cities"));
const City = lazy(() => import("./forms/city"));
const CommissionCategory = lazy(() =>
  import("./forms/commissionCategory")
);
const CommissionCategories = lazy(() =>
  import("./forms/commissionCategories")
);
const CommissionGroup = lazy(() => import("./forms/commissionGroup"));
const CommissionGroups = lazy(() =>
  import("./forms/commissionGroups")
);
const CommissionRate = lazy(() => import("./forms/commissionRate"));
const CommissionRates = lazy(() => import("./forms/commissionRates"));
const Communication = lazy(() => import("./forms/communication"));
const Communications = lazy(() => import("./forms/communications"));
const CommunicationActivity = lazy(() =>
  import("./forms/communicationActivity")
);
const CommunicationActivities = lazy(() =>
  import("./forms/communicationActivities")
);
const CommunicationAudience = lazy(() =>
  import("./forms/communicationAudience")
);
const CommunicationAudiences = lazy(() =>
  import("./forms/communicationAudiences")
);
const CommunicationAudit = lazy(() =>
  import("./forms/communicationAudit")
);
const CommunicationFlow = lazy(() =>
  import("./forms/communicationFlow")
);
const CommunicationFlows = lazy(() =>
  import("./forms/communicationFlows")
);
const CommunicationSettings = lazy(() =>
  import("./forms/communicationSettings")
);
const Contact = lazy(() => import("./forms/contact"));
const Contacts = lazy(() => import("./forms/contacts"));
const ContactsReport = lazy(() => import("./forms/contactsReport"));
const Countries = lazy(() => import("./forms/countries"));
const Country = lazy(() => import("./forms/country"));
const Coupon = lazy(() => import("./forms/coupon"));
const Coupons = lazy(() => import("./forms/coupons"));
const CouponProduct = lazy(() => import("./forms/couponProduct"));
const CouponType = lazy(() => import("./forms/couponType"));
const CouponTypes = lazy(() => import("./forms/couponTypes"));
const CrossSell = lazy(() => import("./forms/crossSellProduct"));
const CustomerAddress = lazy(() => import("./forms/customerAddress"));
const DonationBatchHeader = lazy(() =>
  import("./forms/donationBatchHeader")
);
const DonationBatchHeaders = lazy(() =>
  import("./forms/donationBatchHeaders")
);
const EmailMarketingReport = lazy(() =>
  import("./forms/emailMarketingReport")
);
const FinancialReport = lazy(() => import("./forms/financialReport"));
const Fixture = lazy(() => import("./forms/fixture"));
const Fixtures = lazy(() => import("./forms/fixtures"));
const GlobalSettings = lazy(() => import("./forms/globalSettings"));
const Group = lazy(() => import("./forms/group"));
const Groups = lazy(() => import("./forms/groups"));
const Home = lazy(() => import("./Home"));
const InsightsReport = lazy(() => import("./forms/insightsReport"));
const Invoice = lazy(() => import("./forms/invoice"));
const InvoiceBatchHeader = lazy(() =>
  import("./forms/invoiceBatchHeader")
);
const InvoiceBatchHeaders = lazy(() =>
  import("./forms/invoiceBatchHeaders")
);
const JournalsDailyReport = lazy(() =>
  import("./forms/journalsDailyReport")
);
const Language = lazy(() => import("./forms/language"));
const MarketingList = lazy(() => import("./forms/marketingList"));
const MarketingLists = lazy(() => import("./forms/marketingLists"));
const Membership = lazy(() => import("./forms/membership"));
const MembershipAccessToken = lazy(() =>
  import("./forms/membershipAccessToken")
);
const MembershipAccount = lazy(() =>
  import("./forms/membershipAccount")
);
const MembershipAccounts = lazy(() =>
  import("./forms/membershipAccounts")
);
const MembershipPaymentPlan = lazy(() =>
  import("./forms/membershipPaymentPlan")
);
const MembershipsReport = lazy(() =>
  import("./forms/membershipsReport")
);
const NotFound = lazy(() => import("./NotFound"));
const OnHoldAudit = lazy(() => import("./forms/onHoldAudit"));
const OnHoldReason = lazy(() => import("./forms/onHoldReason"));
const OnHoldReasons = lazy(() => import("./forms/onHoldReasons"));
const OperationalReport = lazy(() =>
  import("./forms/operationalReport")
);
const Operator = lazy(() => import("./forms/operator"));
const Operators = lazy(() => import("./forms/operators"));
const OperatorGroup = lazy(() => import("./forms/operatorGroup"));
const OperatorGroups = lazy(() => import("./forms/operatorGroups"));
const PaymentBatch = lazy(() => import("./forms/paymentBatch"));
const PaymentBatches = lazy(() => import("./forms/paymentBatches"));
const PaymentBatchItems = lazy(() =>
  import("./forms/paymentBatchItems")
);
const PaymentMethod = lazy(() => import("./forms/paymentMethod"));
const PaymentMethods = lazy(() => import("./forms/paymentMethods"));
const PriceBreak = lazy(() => import("./forms/priceBreak"));
const PriceBreakGroup = lazy(() => import("./forms/priceBreakGroup"));
const PriceBreakGroups = lazy(() =>
  import("./forms/priceBreakGroups")
);
const Printer = lazy(() => import("./forms/printer"));
const Printers = lazy(() => import("./forms/printers"));
const PrintTransform = lazy(() => import("./forms/printTransform"));
const PrintTransforms = lazy(() => import("./forms/printTransforms"));
const Product = lazy(() => import("./forms/product"));
const Products = lazy(() => import("./forms/products"));
const ProductCalendar = lazy(() => import("./forms/productCalendar"));
const ProductCalendars = lazy(() =>
  import("./forms/productCalendars")
);
const ProductCategory = lazy(() => import("./forms/productCategory"));
const ProductCategories = lazy(() =>
  import("./forms/productCategories")
);
const ProductModifier = lazy(() => import("./forms/productModifier"));
const Promotion = lazy(() => import("./forms/promotion"));
const Promotions = lazy(() => import("./forms/promotions"));
const PromotionExclusionDate = lazy(() =>
  import("./forms/promotionExclusionDate")
);
const PromotionExclusionDates = lazy(() =>
  import("./forms/promotionExclusionDates")
);
const PromotionProductGroup = lazy(() =>
  import("./forms/promotionProductGroup")
);
const PromotionProductGroups = lazy(() =>
  import("./forms/promotionProductGroups")
);
const PromotionType = lazy(() => import("./forms/promotionType"));
const PromotionTypes = lazy(() => import("./forms/promotionTypes"));
const Question = lazy(() => import("./forms/question"));
const Questions = lazy(() => import("./forms/questions"));
const QuickStart = lazy(() => import("./forms/quickStart"));
const Region = lazy(() => import("./forms/region"));
const Regions = lazy(() => import("./forms/regions"));
const SalesAndBookingsReport = lazy(() =>
  import("./forms/salesAndBookingsReport")
);
const SalesPlan = lazy(() => import("./forms/salesPlan"));
const SalesPlans = lazy(() => import("./forms/salesPlans"));
const SeatAllocation = lazy(() => import("./forms/seatAllocation"));
const SeatClass = lazy(() => import("./forms/seatClass"));
const SeatClasses = lazy(() => import("./forms/seatClasses"));
const Serie = lazy(() => import("./forms/serie"));
const Series = lazy(() => import("./forms/series"));
const Sitemap = lazy(() => import("./forms/sitemap"));
const SubscriptionGroup = lazy(() =>
  import("./forms/subscriptionGroup")
);
const SubscriptionGroups = lazy(() =>
  import("./forms/subscriptionGroups")
);
const TaxCode = lazy(() => import("./forms/taxCode"));
const TaxCodes = lazy(() => import("./forms/taxCodes"));
const TaxComponent = lazy(() => import("./forms/taxComponent"));
const TaxRate = lazy(() => import("./forms/taxRate"));
const TelegramQueue = lazy(() => import("./forms/telegramQueue"));
const Terminal = lazy(() => import("./forms/terminal"));
const Terminals = lazy(() => import("./forms/terminals"));
const TillGroup = lazy(() => import("./forms/tillGroup"));
const TillGroups = lazy(() => import("./forms/tillGroups"));
const VariantPriceList = lazy(() =>
  import("./forms/variantPriceList")
);
const VariantPriceLists = lazy(() =>
  import("./forms/variantPriceLists")
);
const VariantType = lazy(() => import("./forms/variantType"));
const VariantTypes = lazy(() => import("./forms/variantTypes"));
const VenueConfiguration = lazy(() =>
  import("./forms/venueConfiguration")
);
const VenueConfigurationBlock = lazy(() =>
  import("./forms/venueConfigurationBlock")
);
const Venue = lazy(() => import("./forms/venue"));
const Venues = lazy(() => import("./forms/venues"));
const Voucher = lazy(() => import("./forms/voucher"));
const Vouchers = lazy(() => import("./forms/vouchers"));
const Zone = lazy(() => import("./forms/zone"));
const Zones = lazy(() => import("./forms/zones"));

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Home />} />
      <Route path="/abtest" element={<ABTest />} />
      <Route path="/abtests" element={<ABTests />} />
      <Route
        path="/access-control-provider"
        element={<AccessControlProvider />}
      />
      <Route
        path="/access-control-providers"
        element={<AccessControlProviders />}
      />
      <Route path="/access-point" element={<AccessPoint />} />
      <Route path="/access-points" element={<AccessPoints />} />
      <Route path="/account" element={<Account />} />
      <Route path="/accounts" element={<Accounts />} />
      <Route
        path="/additional-product"
        element={<AdditionalProduct />}
      />
      <Route
        path="/additional-products"
        element={<AdditionalProducts />}
      />
      <Route path="/admin-settings" element={<AdminSettings />} />
      <Route path="/answer-group" element={<AnswerGroup />} />
      <Route
        path="/answer-group-option"
        element={<AnswerGroupOption />}
      />
      <Route path="/answer-option" element={<AnswerOption />} />
      <Route
        path="/answer-option-category"
        element={<AnswerOptionCategory />}
      />
      <Route path="/blacklist" element={<Blacklist />} />
      <Route path="/block" element={<Block />} />
      <Route path="/blocks" element={<Blocks />} />
      <Route path="/block-seat" element={<BlockSeat />} />
      <Route
        path="/bookable-resource"
        element={<BookableResource />}
      />
      <Route
        path="/bookable-resources"
        element={<BookableResources />}
      />
      <Route path="/booking" element={<Booking />} />
      <Route path="/bookings" element={<Bookings />} />
      <Route path="/booking-payment" element={<BookingPayment />} />
      <Route path="/booking-ticket" element={<BookingTicket />} />
      <Route path="/calendar-item" element={<CalendarItem />} />
      <Route path="/campaign" element={<Campaign />} />
      <Route path="/campaigns" element={<Campaigns />} />
      <Route path="/channel" element={<Channel />} />
      <Route path="/channels" element={<Channels />} />
      <Route
        path="/channel-price-list"
        element={<ChannelPriceList />}
      />
      <Route
        path="/channel-price-lists"
        element={<ChannelPriceLists />}
      />
      <Route path="/channel-question" element={<ChannelQuestion />} />
      <Route path="/charity" element={<Charity />} />
      <Route path="/cities" element={<Cities />} />
      <Route path="/city" element={<City />} />
      <Route
        path="/commission-category"
        element={<CommissionCategory />}
      />
      <Route
        path="/commission-categories"
        element={<CommissionCategories />}
      />
      <Route path="/commission-group" element={<CommissionGroup />} />
      <Route
        path="/commission-groups"
        element={<CommissionGroups />}
      />
      <Route path="/commission-rate" element={<CommissionRate />} />
      <Route path="/commission-rates" element={<CommissionRates />} />
      <Route path="/communication" element={<Communication />} />
      <Route path="/communications" element={<Communications />} />
      <Route
        path="/communication-activity"
        element={<CommunicationActivity />}
      />
      <Route
        path="/communication-activities"
        element={<CommunicationActivities />}
      />
      <Route
        path="/communication-audience"
        element={<CommunicationAudience />}
      />
      <Route
        path="/communication-audiences"
        element={<CommunicationAudiences />}
      />
      <Route
        path="/communication-audit"
        element={<CommunicationAudit />}
      />
      <Route
        path="/communication-flow"
        element={<CommunicationFlow />}
      />
      <Route
        path="/communication-flows"
        element={<CommunicationFlows />}
      />
      <Route
        path="/communication-settings"
        element={<CommunicationSettings />}
      />
      <Route path="/contact" element={<Contact />} />
      <Route path="/contacts" element={<Contacts />} />
      <Route path="/contacts-report" element={<ContactsReport />} />
      <Route path="/countries" element={<Countries />} />
      <Route path="/country" element={<Country />} />
      <Route path="/coupon" element={<Coupon />} />
      <Route path="/coupons" element={<Coupons />} />
      <Route path="/coupon-product" element={<CouponProduct />} />
      <Route path="/coupon-type" element={<CouponType />} />
      <Route path="/coupon-types" element={<CouponTypes />} />
      <Route path="/cross-sell" element={<CrossSell />} />
      <Route path="/customer-address" element={<CustomerAddress />} />
      <Route
        path="/donation-batch-header"
        element={<DonationBatchHeader />}
      />
      <Route
        path="/donation-batch-headers"
        element={<DonationBatchHeaders />}
      />
      <Route
        path="/email-marketing-report"
        element={<EmailMarketingReport />}
      />
      <Route path="/financial-report" element={<FinancialReport />} />
      <Route path="/fixture" element={<Fixture />} />
      <Route path="/fixtures" element={<Fixtures />} />
      <Route path="/global-settings" element={<GlobalSettings />} />
      <Route path="/group" element={<Group />} />
      <Route path="/groups" element={<Groups />} />
      <Route path="/insights-report" element={<InsightsReport />} />
      <Route path="/invoice" element={<Invoice />} />
      <Route
        path="/invoice-batch-header"
        element={<InvoiceBatchHeader />}
      />
      <Route
        path="/invoice-batch-headers"
        element={<InvoiceBatchHeaders />}
      />
      <Route
        path="/journal-daily-reports"
        element={<JournalsDailyReport />}
      />
      <Route path="/language" element={<Language />} />
      <Route path="/marketing-list" element={<MarketingList />} />
      <Route path="/marketing-lists" element={<MarketingLists />} />
      <Route path="/membership" element={<Membership />} />
      <Route
        path="/membership-access-token"
        element={<MembershipAccessToken />}
      />
      <Route
        path="/membership-account"
        element={<MembershipAccount />}
      />
      <Route
        path="/membership-accounts"
        element={<MembershipAccounts />}
      />
      <Route
        path="/membership-payment-plan"
        element={<MembershipPaymentPlan />}
      />
      <Route
        path="/memberships-report"
        element={<MembershipsReport />}
      />
      <Route path="/on-hold-audit" element={<OnHoldAudit />} />
      <Route path="/on-hold-reason" element={<OnHoldReason />} />
      <Route path="/on-hold-reasons" element={<OnHoldReasons />} />
      <Route
        path="/operational-report"
        element={<OperationalReport />}
      />
      <Route path="/operator" element={<Operator />} />
      <Route path="/operators" element={<Operators />} />
      <Route path="/operator-group" element={<OperatorGroup />} />
      <Route path="/operator-groups" element={<OperatorGroups />} />
      <Route path="/payment-batch" element={<PaymentBatch />} />
      <Route path="/payment-batches" element={<PaymentBatches />} />
      <Route
        path="/payment-batch-items"
        element={<PaymentBatchItems />}
      />
      <Route path="/payment-method" element={<PaymentMethod />} />
      <Route path="/payment-methods" element={<PaymentMethods />} />
      <Route path="/price-break" element={<PriceBreak />} />
      <Route
        path="/price-break-group"
        element={<PriceBreakGroup />}
      />
      <Route
        path="/price-break-groups"
        element={<PriceBreakGroups />}
      />
      <Route path="/printer" element={<Printer />} />
      <Route path="/printers" element={<Printers />} />
      <Route path="/print-transform" element={<PrintTransform />} />
      <Route path="/print-transforms" element={<PrintTransforms />} />
      <Route path="/product" element={<Product />} />
      <Route path="/products" element={<Products />} />
      <Route path="/product-calendar" element={<ProductCalendar />} />
      <Route
        path="/product-calendars"
        element={<ProductCalendars />}
      />
      <Route path="/product-category" element={<ProductCategory />} />
      <Route
        path="/product-categories"
        element={<ProductCategories />}
      />
      <Route path="/product-modifier" element={<ProductModifier />} />
      <Route path="/promotion" element={<Promotion />} />
      <Route path="/promotions" element={<Promotions />} />
      <Route
        path="/promotion-exclusion-date"
        element={<PromotionExclusionDate />}
      />
      <Route
        path="/promotion-exclusion-dates"
        element={<PromotionExclusionDates />}
      />
      <Route
        path="/promotion-product-group"
        element={<PromotionProductGroup />}
      />
      <Route
        path="/promotion-product-groups"
        element={<PromotionProductGroups />}
      />
      <Route path="/promotion-type" element={<PromotionType />} />
      <Route path="/promotion-types" element={<PromotionTypes />} />
      <Route path="/question" element={<Question />} />
      <Route path="/questions" element={<Questions />} />
      <Route path="/quick-start" element={<QuickStart />} />
      <Route path="/region" element={<Region />} />
      <Route path="/regions" element={<Regions />} />
      <Route
        path="/sales-and-bookings-report"
        element={<SalesAndBookingsReport />}
      />
      <Route path="/sales-plan" element={<SalesPlan />} />
      <Route path="/sales-plans" element={<SalesPlans />} />
      <Route path="/seat-allocation" element={<SeatAllocation />} />
      <Route path="/seat-class" element={<SeatClass />} />
      <Route path="/seat-classes" element={<SeatClasses />} />
      <Route path="/serie" element={<Serie />} />
      <Route path="/series" element={<Series />} />
      <Route path="/sitemap" element={<Sitemap />} />
      <Route
        path="/subscription-group"
        element={<SubscriptionGroup />}
      />
      <Route
        path="/subscription-groups"
        element={<SubscriptionGroups />}
      />
      <Route path="/tax-code" element={<TaxCode />} />
      <Route path="/tax-codes" element={<TaxCodes />} />
      <Route path="/tax-component" element={<TaxComponent />} />
      <Route path="/tax-rate" element={<TaxRate />} />
      <Route path="/telegram-queue" element={<TelegramQueue />} />
      <Route path="/terminal" element={<Terminal />} />
      <Route path="/terminals" element={<Terminals />} />
      <Route path="/till-group" element={<TillGroup />} />
      <Route path="/till-groups" element={<TillGroups />} />
      <Route
        path="/variant-price-list"
        element={<VariantPriceList />}
      />
      <Route
        path="/variant-price-lists"
        element={<VariantPriceLists />}
      />
      <Route path="/variant-type" element={<VariantType />} />
      <Route path="/variant-types" element={<VariantTypes />} />
      <Route path="/venue" element={<Venue />} />
      <Route path="/venues" element={<Venues />} />
      <Route
        path="/venue-configuration"
        element={<VenueConfiguration />}
      />
      <Route
        path="/venue-configuration-block"
        element={<VenueConfigurationBlock />}
      />
      <Route path="/voucher" element={<Voucher />} />
      <Route path="/vouchers" element={<Vouchers />} />
      <Route path="/zone" element={<Zone />} />
      <Route path="/zones" element={<Zones />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

function App() {
  return (
    <GlobalProvider>
      <Suspense fallback={<Loading />}>
        <RouterProvider router={router} />
      </Suspense>
    </GlobalProvider>
  );
}

export default App;
